import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    navColor: "#fff",
    activeMenu: "",
  },
  getters: {},
  mutations: {
    SET_NAV_COLOR(state, val) {
      state.navColor = val;
    },
    SET_ACTIVE_MENU(state, val) {
      state.activeMenu = val;
    },
  },
  actions: {},
  modules: {},
});
